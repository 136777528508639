import React from 'react';

import GenerateLink from '@components/GenerateLink';
import { styWrapper } from '@components/GenerateLink/styles';
import MainLayout from '@components/Layout';

function GenerateLinkWrapper() {
  return (
    <MainLayout>
      <div css={styWrapper}>{<GenerateLink />}</div>
    </MainLayout>
  );
}

export default GenerateLinkWrapper;
